body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  background-color: "#161616";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  position: relative;
  width: 250px;
  height: 300px;
  border-radius: 14px;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 25px #ed7d31;
}

.bg {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 240px;
  height: 290px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
  border-radius: 10px;
  overflow: hidden;
  outline: 2px solid white;
}

.blob {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ed7d31;
  opacity: 1;
  -webkit-filter: blur(12px);
          filter: blur(12px);
  -webkit-animation: blob-bounce 5s infinite ease;
          animation: blob-bounce 5s infinite ease;
}

@-webkit-keyframes blob-bounce {
  0% {
    -webkit-transform: translate(-100%, -100%) translate3d(0, 0, 0);
            transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    -webkit-transform: translate(-100%, -100%) translate3d(100%, 0, 0);
            transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    -webkit-transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
            transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    -webkit-transform: translate(-100%, -100%) translate3d(0, 100%, 0);
            transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    -webkit-transform: translate(-100%, -100%) translate3d(0, 0, 0);
            transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}

@keyframes blob-bounce {
  0% {
    -webkit-transform: translate(-100%, -100%) translate3d(0, 0, 0);
            transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    -webkit-transform: translate(-100%, -100%) translate3d(100%, 0, 0);
            transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    -webkit-transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
            transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    -webkit-transform: translate(-100%, -100%) translate3d(0, 100%, 0);
            transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    -webkit-transform: translate(-100%, -100%) translate3d(0, 0, 0);
            transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

